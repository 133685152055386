import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Content, { HTMLContent } from 'components/Content'
import SEO from 'components/SEO'
import Container from 'components/Container'
import { Text } from 'components/Typography'

export const AboutPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <Container as="section" my={5} mx="auto" px={[3, 0]} maxWidth={1150}>
      <Text as="h1" mb={5} fontSize={[32, 36, 40]} color="textColor">
        За Асоциация Родители
      </Text>
      <PageContent content={content} color="textColor" />
    </Container>
  )
}

AboutPageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func.isRequired,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  const { footerData = {}, headerData = {} } = data

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={post.frontmatter.title} />
      <AboutPageTemplate contentComponent={HTMLContent} content={post.html} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
